import React from "react";

import Ready from "../ready/ready";
import Setting from "../setting/setting";
import Subscribe from "../subscribe/subscribe_2";
import TopBanner from "../top_banner/product_top_banner";
import Approach from "../human_approach/approach";
import { useTranslation } from 'react-i18next';

const ProductExtension = () => {
  const {t} = useTranslation();
  const DataArray = [
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/signal_in_sqaure_icon_with_bg.svg",
      title: `${t('recording_settings')}`,
      description: `${t('recording_protection_is_ensured_by_encryption')}`,
      listItems: [
        `${t('webcam_recording')}`,
        `${t('audio_recording')}`,
        `${t('screen_recording')}`,
        `${t('saving_the_workspace')}`,
        `${t('registration_of_identity_document')}`,
      ],
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/setting_in_sqaure_icon_with_bg.svg",
      title: `${t('lockdown_browser_settings')}`,
      description: `${t('access_to_external_documents_during_the_exam_session')}`,
      listItems: [
        `${t('full_screen_mode_enabled')}`,
        `${t('other_screen_detected')}`,
        `${t('new_tab_inaccessible')}`,
        `${t('browser_cleaned_upstream')}`,
        `${t('cache_cleaned_downstream')}`,
        `${t('printer_disabled')}`,
        `${t('extension_disabled')}`,
        `${t('right_click_disabled')}`,
        `${t('disabled_keyboard_shortcuts')}`,
      ],
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/eye_in_square_icon_with_bg.svg",
      title: `${t('automated_monitoring')}`,
      description:`${t('when_comparing_mereos_to_other_remote_monitoring')}`,
      listItems: [
        `${t('no_downloads')}`,
        `${t('test_accessible_in_1_click')}`,
        `${t('custom_interface')}`,
        `${t('multi_format_assessments')}`,
        `${t('advanced_grade_analysis')}`,
        "Catalogue APIs",
      ],
    },
  ];
  return (
    <>
      <div className={`conatiner font-sans`}>
        <TopBanner
          pageTitle="EXTENSION MEREOS"
          title={t('monitored_assessments_in_your_lms')}
          description={t('the_mereos_extension_integrates_with_your_lms')}
          imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/people_connecting_switch.svg"
          leftButton={{
            text: `${t("free_trials")}`,
            href: "/free-trial",
          }}
          rightButton={{
            text: `Demo >`,
            href: "/demo",
          }}
        />

        {/* sub Container */}
        <div className={`mx-auto w-11/12 lg:w-5/6 my-32`}>
          <Setting
            title={t('surveillance_adapted_to_higher_education')}
            subTitle={t('with_mereos_the_following_monitoring')}
            Data={DataArray}
          />

          <div className="my-20">
            <Approach
              title={t('automated_surveillance_kesako')}
              description1={t('each_exam_session_is_analyzed_by_mereos')}
              description2={t('when_a_session_is_deemed_suspicious')}
              imgSrc="https://d2lxkizvrhu4im.cloudfront.net/images/platform_top_banner_site_structure.svg"
            />
          </div>
        </div>

        {/* <AutomatedLiveComponent/> */}
        <div className={`mx-auto w-11/12 lg:w-5/6 `}>
          {/* Ready */}
          <div className="my-32">
            <Ready />
          </div>
          {/* <div className="my-20"> */}
          {/* <Analytics /> */}
          {/* </div> */}
        </div>
        <Subscribe />
      </div>
    </>
  );
};

export default ProductExtension;
