import * as React from "react";

import Layout from "../components/layout/layout";
import ProductExtensionComponent from "../components/product-page/product_extension";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const ProductExtension = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title="Extension"
        description={`${t('a_revolutionary_solution_for_creating_secure')}`}
      />
      <Layout>
        <ProductExtensionComponent />  
      </Layout>
    </>
  )
}

export default ProductExtension
